<script setup lang="ts">
const props = defineProps<{ tag: string | Component }>();
</script>

<template>
  <component
    :is="props.tag"
    class="app-header-actions-item min-w-12 min-h-12 flex items-center justify-center gap-2 px-3 py-3"
    v-bind="$attrs"
  >
    <slot v-bind="{ props }"> </slot>
  </component>
</template>
