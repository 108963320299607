<script setup lang="ts">
import type { FooterSocialItem } from "~/composables/useFooter";

const props = defineProps<{
  item: FooterSocialItem;
}>();
</script>

<template>
  <a
    :href="props.item.link"
    :title="props.item.title"
    target="_blank"
    rel="noopener nofollow"
    class="app-footer-social-item"
  >
    <Icon :name="props.item.icon" class="block w-10 h-10 lg:h-6 lg:w-6" />
  </a>
</template>
